/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li",
    h3: "h3"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "your-key-to-the-world",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#your-key-to-the-world",
    "aria-label": "your key to the world permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Your Key To the World"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You deserve a ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aid"), " that’s as active as you are, which is why ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/resound/",
    className: "c-md-a"
  }, "ReSound"), " created the highly adaptable, versatile, and technology-packed Enya to meet your hearing aid needs. With two available technology levels, the ReSound Enya is easily customizable to address your specific hearing concerns in any listening environment."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Thanks to the ReSound Enya hearing aid and it’s high-tech features, anything is possible. Hearing loss shouldn’t hold you back, so get ready to meet the ReSound Enya hearing aid – your key to a life well lived."), "\n", React.createElement(LandingPageCta, {
    copy: "TRY THE RESOUND ENYA NOW",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resound-enya-an-overview",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resound-enya-an-overview",
    "aria-label": "resound enya an overview permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ReSound Enya: An Overview"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Two technology levels to help every listener"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Adaptive directionality technology for a more natural listening experience"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "NoiseTracker II reduces unwanted background noise without affecting sound quality"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "DFS Ultra II filters out and eliminated annoying feedback"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Whistle Control technology provides optimal amplification in dynamic environments"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Dual microphone WindGuard technology reduces the effect of wind on noise quality"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Comfort Phone automatically switches to a smartphone’s hearing program for a better conversation"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "SureFit design provides an ideal hearing aid fitting, every time"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "ReSound Control app for wireless settings customization"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "ReSound Relief app can offer multisensory tinnitus relief"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "two-technology-levels-for-every-listener",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#two-technology-levels-for-every-listener",
    "aria-label": "two technology levels for every listener permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Two Technology Levels for Every Listener"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "ReSound knows that no two people are alike, so they’ve created two different technology levels in their Enya hearing aids to better meet the needs of their customers. ReSound knows that you’re unique, so your hearing aids should be, too."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For people with a moderately social lifestyle, the ReSound Enya 2 provides a quality listening experience that’s easy to control, comfortable, and reliable. The ReSound Enya 2 is best used in less noisy listening environments, so it’s a great option for people who spend most of their time in quiet, small group situations."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "On the other hand, the ReSound Enya 3 is designed specifically for the social butterflies among us who don’t let hearing loss get in the way of living their best lives. The ReSound Enya 3 is easy to control wirelessly with the ReSound Control app and other wireless accessories. Plus, with a built-in tinnitus sound generator, there’s a lot to love with the Enya 3."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "a-more-natural-listening-experience",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#a-more-natural-listening-experience",
    "aria-label": "a more natural listening experience permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A More Natural Listening Experience"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When you have hearing loss, it can be difficult to stay on top of a large group conversation. Business meetings, family dinners, and social get-togethers can quickly turn into a source of anxiety if you have trouble figuring out who’s talking at any given moment. Thanks to the ReSound Enya’s Adaptive Directionality technology, however, this problem is a thing of the past."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "By using multiple directional microphones that automatically switch to follow the sound of human speech, the ReSound Enya provides a more natural listening experience, every time."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "background-noise-reduction",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#background-noise-reduction",
    "aria-label": "background noise reduction permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Background Noise Reduction"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It can be difficult enough to stay on top of a lively conversation but throw a noisy environment into the mix and all bets are off. With ReSound’s NoiseTracker II, however, the Enya hearing aids can reduce unwanted background noise without reducing sound quality to help you stay engaged in every situation."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "windproof-listening",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#windproof-listening",
    "aria-label": "windproof listening permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Windproof Listening"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your days of shouting in the wind are over, thanks to the ReSound Enya’s WindGuard technology. With two microphones that can quickly and accurately identify wind noise, the ReSound Enya can eliminate the annoying sound of wind from your ear for an easier, more comfortable conversation in foul weather."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "farewell-feedback",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#farewell-feedback",
    "aria-label": "farewell feedback permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Farewell, Feedback"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "ReSound Enya’s DFS Ultra II technology makes annoying feedback a thing of the past. The hearing aids’ adaptive filter can efficiently identify any feedback and cancel it out before you even have the chance to realize what’s happening. Plus, the ReSound Enya’s Whistle Control predicts when feedback will happen and adjusts your settings to find the right amplification in a dynamic listening environment."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "seamless-smartphone-settings",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#seamless-smartphone-settings",
    "aria-label": "seamless smartphone settings permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Seamless Smartphone Settings"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your hearing aid should make it easier to talk on the phone, which is why ReSound built the Enya with Comfort Phone technology. Designed specifically to help reduce the effects of background noise and to make it easier for you concentrate on your conversation, as soon as you hold up a phone to your ear, the ReSound Enya quickly switches to a smartphone-integrated hearing program and reduces the sound level in your non-phone ear by 6dB."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "smartphone-apps-revolutionize-your-hearing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#smartphone-apps-revolutionize-your-hearing",
    "aria-label": "smartphone apps revolutionize your hearing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Smartphone Apps Revolutionize Your Hearing"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The ReSound Enya is specifically designed to stream high-quality audio from a smartphone or device, right into your ear using wireless accessories. Plus, with the ReSound Control app, Enya users can quickly and effortlessly adjust their settings without skipping a beat. If that wasn’t good enough, the Enya also comes with the ReSound Relief app to help provide multisensory tinnitus relief."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "interested-in-the-resound-enya",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#interested-in-the-resound-enya",
    "aria-label": "interested in the resound enya permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Interested in the ReSound Enya"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Are you overwhelmed with all of the different hearing aids options available to you? Do you need guidance to choose the hearing aid that’s right for your needs and style? We’re here to help. Contact us today to speak with one of our hearing experts and find out if the ReSound Enya is the right hearing aid for you. Simply call us or click the “Get started” button below to see if you qualify for a 45 day risk-free trial."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
